import axios from "axios";


const allowedIps = ['14.98.30.186', '115.244.210.174', '106.51.76.84' ,'106.51.121.122', '49.37.243.2', '49.207.50.137']


export const fetchUserIp = async () => {
    try {
        const response = await axios.get("https://api.ipify.org?format=json");

        return allowedIps.includes(response?.data?.ip);
    } catch (error) {
        console.log(error, 'Error fetching the user\'s IP address...');
        return false;
    }
};

