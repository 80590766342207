import { Grid } from '@mui/material'
import React from 'react'
import logo from "../../../images/logo_1.png"
import mosLogo from "../../../images/moslogo.png"

const Header = () => {
    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
                <img 
                    src={logo} 
                    alt="Logo" 
                    width="220px" 
                    height="220px" 
                    style={{
                        position:'relative',
                        bottom:79,
                        left:30
                    }}
                />
            </Grid>

            <Grid item>
                <img 
                    src={mosLogo} 
                    alt="MOS Logo" 
                    width="170px" 
                    height="170px"
                    style={{
                        position:'relative',
                        bottom:72,
                    }}  
                />
            </Grid>
        </Grid>
    )
}

export default Header
