import React, { createContext, useState, useContext, useEffect } from 'react';
import URL from './Components/Global/Utils/url_route';

const SharedContext = createContext();


export function SharedProvider({ children }) {
    const [sharedTab, setSharedTab] = useState({
        Tabname: null,
        TabUrl: null,
        active: 0,
        backendUrl: null
    });

    const [rerender, setRerender] = useState(false)

    const [insertRequest, setInsertRequest] = useState('')

    const [employeeAddTab, setEmployeeAddTab] = useState({
        candidateId: null,
        status: 0
    });


    const [userRoleManagement, setuserRoleManagement] = useState(
        JSON.parse(sessionStorage.getItem("userRole")) || []
    );


    return (
        <SharedContext.Provider value={{ sharedTab, setSharedTab, rerender, setRerender, insertRequest, setInsertRequest, employeeAddTab, setEmployeeAddTab, userRoleManagement, setuserRoleManagement, }}>
            {children}
        </SharedContext.Provider>
    );
}


export function useSharedContext() {
    return useContext(SharedContext);
}
