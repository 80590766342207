import * as React from 'react';
import axios from 'axios';
import URL from "../Global/Utils/url_route";
import { Grid, TextField, Card, CardContent, Box, CardHeader, CardMedia, Button, Container, Typography, Paper, CssBaseline, styled } from "@mui/material"
import logo1 from "../../images/logo_1.png";
import { Link, useNavigate } from 'react-router-dom';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { useSharedContext } from '../../Context';


function MOSLogin() {
  const [user_name, setUser_name] = React.useState("");
  const [user_pwd, setUser_pwd] = React.useState("");
  const [errorMsg, setError] = React.useState("");
  const [employeeLogin, setEmployeeLogin] = React.useState(false);
  const history = useNavigate();

  const { setuserRoleManagement } = useSharedContext();

  const JourneyButton = styled(Button)({
    background: "#ECE6DB",
    color: "black",
    '&:hover': {
      background: "#CDCDCD",
    }
  })


  const handleValidate = () => {
    let request = { "user_name": user_name, "user_pwd": user_pwd, "type": "login" };

    let url = URL + "login/validateUser";


    axios.post(url, request)

      .then((response) => {

        if (response.data.status === 0) {

          const user_info = response.data.data[0];

          const userRoleData = response?.data?.userRoleData;

          setuserRoleManagement(userRoleData)

          sessionStorage.setItem("userRole", JSON.stringify(userRoleData))

          sessionStorage.setItem("user_info", JSON.stringify(user_info));

          const userRoleAvailableInSession = sessionStorage.getItem("userRole");

          if (userRoleAvailableInSession) {

            history("/dashboard");

          }


        }
        else {

          setError(response.data.message)
        }
      }).catch(function (error) {
        console.log(error);
      });
  }

  const apiCallOnEnter = (event) => {
    if (event.key === 'Enter') {
      handleValidate();
    }
  }

  const apiCallonButton = () => {
    handleValidate();
  }


  return (

    <Grid container sx={{ height: '100vh' }}>
      <CssBaseline />

      <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '' }}>
        <Box
          sx={{
            height: '70vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >

          <DotLottieReact
            src="/loginscren.json"
            loop
            autoplay
          />


        </Box>
        <Typography variant="h2" align="center">
        </Typography>

        <CardMedia
          component="img"
          image={logo1}
          alt="Brhma"
          height="200"
          sx={{
            position: 'absolute',
            top: -20,
            left: 50,
            width: 200,
            height: 200,
          }}
        />
      </Grid>

  
      <Grid item xs={6} sx={{ height: '100%', backgroundColor: ' #142850 ', borderTopLeftRadius:15 , borderBottomLeftRadius:15 }}>

        {employeeLogin &&
          <Grid>
            <Grid item >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center', 
                  height: '100%',
                  backgroundColor: '#142850',
                  paddingTop: 12,
                }}
              >
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ maxWidth: 400, width: '100%' }}>
                  <Grid pb={3} display="flex" direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                      <Typography sx={{ color: 'white' }} variant="h3" gutterBottom>
                        Welcome Back to
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ color: 'white' }} variant="h3" gutterBottom>
                        Brhma !
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3, backgroundColor: '#142850', }}>

                <Grid container direction="column" alignItems="center" sx={{ maxWidth: 400, width: '100%' }}>
                  <Grid item sx={{ width: '100%' }}>
                    <Typography sx={{ color: 'white', fontSize: 20 }}>User Name</Typography>
                    <TextField fullWidth margin="normal" sx={{
                      bgcolor: 'white', borderRadius: 2,
                      '& .MuiInputBase-input': {
                        fontSize: '18px',
                        padding: '10px',
                      },
                    }}
                      onChange={(e) => setUser_name(e.target.value)}
                    />
                    <Typography sx={{ color: 'white', fontSize: 20 }}>Password</Typography>
                    <TextField fullWidth type="password" margin="normal" sx={{
                      bgcolor: 'white', borderRadius: 2, '& .MuiInputBase-input': {
                        fontSize: '18px',
                        padding: '10px',
                      }
                    }}
                      onChange={(e) => setUser_pwd(e.target.value)}
                      onKeyDown={(e) => apiCallOnEnter(e)}
                    />

                    <Box sx={{ margin: '10px 0', pt: 3 }}>
                      <Button
                        variant="contained"
                        color="info"
                        disableElevation
                        fullWidth
                        disabled={user_name === "" || user_pwd === ""}
                        sx={{ backgroundColor: '#7b96d4', p: 1, fontSize: '15px', }}
                        onClick={() => apiCallonButton()}
                      >
                        LOGIN
                      </Button>
                    </Box>
                    <Box style={{ margin: "10px", color: "red" }} visibility={errorMsg === "" ? "hidden" : "visible"}>
                      {errorMsg}
                    </Box>
                  </Grid>
                </Grid>

              </Box>
            </Grid>
          </Grid>
        }

        {!employeeLogin &&
          <Grid container sx={{ height: "100vh" }}>
            <Grid item xs={12} sm={12} md={12} sx={{ height: "30%" }}>
              <img
                src={logo1}
                alt='brmha'
                width={"300px"}
                height={"300px"}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop:'20%'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
              <Link to={"/candidate"}>
                <JourneyButton variant='contained' disableElevation disableFocusRipple>
                  Candidate registration</JourneyButton>
              </Link>
              <JourneyButton
                variant='contained'
                disableElevation
                disableFocusRipple
                sx={{
                  marginLeft: "8px",
                  paddingLeft: "50px",
                  paddingRight: "50px"
                }}
                onClick={() => setEmployeeLogin(true)}
              >
                {" "}Employee Login{" "}</JourneyButton>
            </Grid>

          </Grid>
        }

      </Grid>

    </Grid >


  )

};


export default MOSLogin;




