import * as React from 'react';
import { Grid, Typography } from "@mui/material";


function MOSFooter() {
    return (
        <Grid container height={'100%'} >
            <Grid item xs={12} sm={12} md={12} sx={{  marginTop: "0", bottom: "0" ,display:'flex' , justifyContent:'center',alignItems:'center' }}>
                <Typography variant='body1' color='white'>
                    Copyright @ MegaaOpes
                </Typography>
            </Grid>
        </Grid>
    )
}


export default MOSFooter;