import * as React from 'react';
import logo1 from "../../../images/logo_1.png";
import { Grid, Button, Typography } from "@mui/material";


function UnauthorizedPage() {


    return (
        <Grid container sx={{ background: "#142850", height: "100vh" }}>
            <Grid item xs={12} sm={12} md={12} sx={{ height: "7%" }}>
                <img src={logo1} alt='brmha' width={"300px"} height={"300px"} style={{ display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "10%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
                <Typography variant='h3' color='white'> Unauthorized Access</Typography>
                <Typography variant='h6' color='white'> Access Denied for Brhma </Typography>
            </Grid>

        </Grid>
    )
}


export default UnauthorizedPage
